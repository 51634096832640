/**
 * User Helper is a Singleton Class which provides access to methods which help interact with the Claims Gate User Backend
 */
import { getFirebaseBackend } from "@/authUtils.js";
import store from "@/state/store";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { AsyncHelper } from "./AsyncHelper";

import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";

class UserHelper {
  variableService;
  db = null;

  constructor(db) {
    if (db) {
      this.db = db;
    } else {
      this.db = getFirebaseBackend().firestore();
    }
    this.variableService = new VariableService(this.db);
  }
  /**
   * Creates a new user in Firebase Authentication and authenticates the requesting user
   * @return {Boolean} - Returns boolean to indicate if a new Firebase Account was created
   */
  createUser = async () => {
    if (getFirebaseBackend().getAuthenticatedUser() === null) {
      await store.dispatch("auth/anonLogIn");
      return true;
    } else {
      return false;
    }
  };

  /**
   * Gets current user in Firebase Authentication
   * Returns User or null
   */
  getUser = () => {
    return getFirebaseBackend().getAuthenticatedUser();
  };

  /**
   * Creates an object which contains the Claims Gate Tracking Parameters if they have been set in LocalStorage
   * @return {Object} - An Object containing the UTM Parameters found in Local Storage: {utm_medium, utm_source, utm_campaign, ocode};
   */
  getOriginationMetaFromLS = () => {
    var originationMeta = {};

    var ocode = localStorage.getItem("ocode");
    if (ocode && typeof ocode === "string" && ocode.length > 0) {
      originationMeta.ocode = ocode;

      var utm_medium = localStorage.getItem("utm_medium");
      if (utm_medium && typeof utm_medium === "string" && utm_medium.length > 0) {
        originationMeta.utm_medium = utm_medium;
      }

      var utm_source = localStorage.getItem("utm_source");
      if (utm_source && typeof utm_source === "string" && utm_source.length > 0) {
        originationMeta.utm_source = utm_source;
      }

      var utm_campaign = localStorage.getItem("utm_campaign");
      if (utm_campaign && typeof utm_campaign === "string" && utm_campaign.length > 0) {
        originationMeta.utm_campaign = utm_campaign;
      }

      var meta = {};

      if (localStorage.getItem("adId")) {
        meta.adId = localStorage.getItem("adId");
      }

      if (localStorage.getItem("source")) {
        meta.source = localStorage.getItem("source");
      }

      if (localStorage.getItem("refer")) {
        meta.refer = localStorage.getItem("refer");
      }
      originationMeta.meta = meta;
    }
    return originationMeta;
  };

  /**
   * Creates an object whcih contains the Claims Gate Meta Tracking parameters if they have been set in local storage
   * @return {Object} - An object containing the Tracking Parameters found in Local Storage: {adId, source, refer}
   */
  getMetaFromLS = () => {
    var meta = {};

    var adId = localStorage.getItem("adId");
    if (adId && typeof adId === "string" && adId.length > 0) {
      meta.adId = localStorage.getItem("adId");
    }

    var source = localStorage.getItem("source");

    if (source && typeof source === "string" && source.length > 0) {
      meta.source = source;
    }

    var refer = localStorage.getItem("refer");

    if (refer && typeof refer === "string" && refer.length > 0) {
      meta.refer = refer;
    }
    return meta;
  };

  createClaimId = () => {
    const db = getFirebaseBackend().firestore();
    const userHelper = getUserHelper();
    const userRef = db.collection("users").doc(userHelper.getUserId());

    const claimRef = userRef.collection("claims").doc();
    return claimRef.id;
  };

  /**
   * Creates a new claim on the requesting user
   * @param  {[type]}  claimType - String identifying type of claim to create, options: {tesco}
   * @param  {[type]}  solicitorUid - String identifier of the solicitor who owns the claim
   * @param  {[type]}  origination - Optional object containing origination details
   * @return {Object} - // TODO
   */
  createClaim = async (claimType, solicitorUid, origination = {}, meta = {}) => {
    var createUserClaim = getFirebaseBackend().firebaseFunctions().httpsCallable("createUserClaim");

    const validClaimTypes = ["tesco", "emissions", "easyjet", "equifax", "hammersmith", "ticketmaster"];

    if (!validClaimTypes.includes(claimType)) {
      return false;
    }
    var x = await createUserClaim({
      Type: claimType,
      claimId: claimType.toUpperCase(),
      solicitor: solicitorUid,
      originator: origination,
      meta: meta,
    });
    window.console.log(x);

    return true;
  };

  /**
   * Retrieves the requesting user's user data from Firestore
   * @see {Object} - Empty object will be returned if the requesting user is not authetnicated
   * @return {Object} - {key1: val1, key2: val2} Data associated with user from Firestore
   */
  getUserData = async () => {
    let db;
    let uid;
    try {
      db = getFirebaseBackend().firestore();
      uid = getFirebaseBackend().getAuthenticatedUser().uid;
    } catch (exception) {
      console.log("no user found");
      return null;
    }

    if (getFirebaseBackend().getAuthenticatedUser() === null) {
      return null;
    } else {
      const userRef = db.collection("users").doc(uid);
      const userSnapshot = await userRef.get();
      if (!userSnapshot.exists) {
        return null;
      } else {
        return userSnapshot.data();
      }
    }
  };

  getNextStep = async (claimId) => {
    var db = getFirebaseBackend().firestore();
    var user = getFirebaseBackend().getAuthenticatedUser();

    if (user === null) {
      return false;
    }
    var uid = getFirebaseBackend().getAuthenticatedUser().uid;

    window.console.log(uid);
    // Fetch data for requesting user from firestore
    var userRef = db.collection("users").doc(uid);
    var claimRef = userRef.collection("claims").doc(claimId.toUpperCase());

    var claimSnapshot = await claimRef.get();
    window.console.log("CSEXISTS", claimSnapshot.exists);
    if (claimSnapshot.exists) {
      const { steps } = claimSnapshot.data();
      window.console.log(steps);
      // Ensure the user has steps and at at least one remaining
      if (steps && steps.length > 0) {
        var nextStepRef = steps[0];
        var nextStepSnapshot = await nextStepRef.get();

        if (nextStepSnapshot.exists) {
          return nextStepSnapshot.data();
        } else {
          return true;
        }
      }
      if (!steps || steps.length === 0) {
        return true;
      }
    } else {
      return false;
    }
  };

  /**
   * Returns each of the claims available on the requesting user
   */
  getClaims = async () => {
    const db = getFirebaseBackend().firestore();

    const userHelper = getUserHelper();
    const userRef = db.collection("users").doc(userHelper.getUserId());
    const claimsRef = userRef.collection("claims");
    const claimsSnapshot = await claimsRef.get();

    const claimsData = claimsSnapshot.docs.map((document) => document.data());

    return AsyncHelper.onCompleted(claimsData);
  };

  getClaimsByFunnelId = async (funnelId) => {
    const db = getFirebaseBackend().firestore();
    const userHelper = getUserHelper();
    const userRef = db.collection("users").doc(userHelper.getUserId());
    const claimsRef = userRef.collection("claims").where("currentFunnelId", "==", funnelId);
    const claimsSnapshot = await claimsRef.get();

    const claimsData = claimsSnapshot.docs.map((document) => document.data());

    return AsyncHelper.onCompleted(claimsData);
  };

  /**
   * Retrieves claim data for the requesting user in Firestore
   * @param  {string}  claimId - Unique identifier for user's claim
   * @return {key1: val1, key2: val2} - Data associated with user's claim from Firestore
   */
  getClaimData = async (claimId) => {
    if (getFirebaseBackend().getAuthenticatedUser() === null) {
      return null;
    } else {
      var db = getFirebaseBackend().firestore();
      var uid = getFirebaseBackend().getAuthenticatedUser().uid;

      // Fetch data for requesting user from firestore
      var userRef = db.collection("users").doc(uid);
      var claimRef = userRef.collection("claims").doc(claimId);
      var claimSnapshot = await claimRef.get();

      if (!claimSnapshot.exists) {
        return null;
      } else {
        try {
          const claimDataService = new ClaimDataService(uid, claimId);
          const translatedData = await this.variableService.translateData(claimSnapshot.data());
          const parsedData = claimDataService.parseData(translatedData, uid);
          return parsedData;
        } catch (e) {
          return claimSnapshot.data();
        }
      }
    }
  };
  /**
   * Removes a passed step from a given claim on the requesting user
   * @param  {[type]}  claimId claim which is owned by the requesting user
   * @param  {[type]}  step name of the claim to be removed
   * @return {Promise}
   */
  completeStep = async (claimId, step) => {
    // TODO: Validate the passed step to ensure it is contained in the list of valid steps
    const db = getFirebaseBackend().firestore();
    const userRef = db.collection("users").doc(getFirebaseBackend().getAuthenticatedUser().uid);

    // Authenticity of given claim
    const claimRef = userRef.collection("claims").doc(claimId);
    const claimSnapshot = await claimRef.get();

    // Claim is invalid
    if (!claimSnapshot.exists) {
      return null;
    }

    // Fetch reference of step to delete
    var stepsQueryRef = getFirebaseBackend().firestore().collection("steps").where("route", "==", step);
    var stepsQuerySnapshot = await stepsQueryRef.get();

    // Step to delete is not a valid step
    if (stepsQuerySnapshot.empty) {
      return null;
    }

    // Remove step from array of steps to complete
    var stepSnapshot = stepsQuerySnapshot.docs[0];
    var stepRef = stepSnapshot.ref;
    await claimRef.update({
      steps: getFirebaseBackend().firestoreClass().FieldValue.arrayRemove(stepRef),
    });

    return true;
  };

  /**
   * Stores given data on the requesting user in firestore
   * @param  { Object}  data - Object containing data to store
   * @return {Promise}
   */
  setUserData = async (data) => {
    try {
      const db = getFirebaseBackend().firestore();
      const userRef = db.collection("users").doc(getFirebaseBackend().getAuthenticatedUser().uid);

      await userRef.set(data, { merge: true });
    } catch (e) {
      window.console.log("Error when trying to set user data", e);
    }

    return true;
  };

  fetchApiKey = async (keyName) => {
    const db = getFirebaseBackend().firestore();

    if (keyName === "t2a") {
      const t2aKeyRef = db.collection("globals").doc("t2a");
      const t2aKeySnapshot = await t2aKeyRef.get();
      const { javascript_key, api_key } = t2aKeySnapshot.data();
      if (javascript_key) return javascript_key;
      else return api_key;
    }
  };

  /**
   * Stores given data on the requesting user's claim in Firestore
   * @param  {[type]}  claimId - Claim to store data on
   * @param  {[type]}  data - Object containing data to store
   * @return {Promise}
   */
  setClaimData = async (claimId, data) => {
    const db = getFirebaseBackend().firestore();
    const userRef = db.collection("users").doc(getFirebaseBackend().getAuthenticatedUser().uid);
    const claimRef = userRef.collection("claims").doc(claimId);
    await claimRef.set(data, { merge: true });
    return true;
  };

  /**
   * Fetches a document from a given path in Firestore
   * @param {*} documentPath
   * @returns
   */
  getDocument = async (documentPath) => {
    const db = getFirebaseBackend().firestore();
    const documentRef = db.doc(documentPath);
    const documentSnapshot = await documentRef.get();

    if (!documentSnapshot.exists) {
      return undefined;
    }

    return documentSnapshot.data();
  };

  /**
   * Fetches a document from a given path in Firestore
   * @param {*} collectionPath
   * @returns
   */
  getCollection = async (documentPath) => {
    const db = getFirebaseBackend().firestore();
    const collectionRef = db.collection(documentPath);
    const collecitonQuerySnapshot = await collectionRef.get();

    if (collecitonQuerySnapshot.empty) {
      return undefined;
    }

    return collecitonQuerySnapshot;
  };

  /**
   * Translates a claim type to the associated configuration name
   * @param  {[type]} claimType
   * @return {[type]}
   */
  getClaimConfigName = (claimType) => {
    const oldVersion = {
      MERC_EMISSIONS: "mercedes",
      VW_EMISSIONS: "volkswagen",
      VAUXHALL_EMISSIONS: "vauxhall",
    };
    if (oldVersion[claimType]) {
      return oldVersion[claimType];
    } else {
      return claimType;
    }
  };

  getMainConfig = () => {
    return this.mainConfig;
  };

  getUserId = () => {
    const user = getFirebaseBackend().getAuthenticatedUser();
    if (user !== null) {
      return user.uid;
    }
    return null;
  };

  async getCompanies(userId) {
    const getUserCompanies = getFirebaseBackend().firebaseFunctions().httpsCallable("getUserCompanies");

    const { data, exception } = await getUserCompanies(userId);

    if (exception) {
      console.log(exception);
      return exception;
    }

    return data;
  }

  /**
   * Returns the API key associated with a given user
   * @params {string} userId
   */
  async getApiKey(userId) {
    const solicitorSnapshot = await this.db.collection("keys").where("solicitorUid", "==", userId).get();
    const originatorSnapshot = await this.db.collection("keys").where("originatorUid", "==", userId).get();

    let apiKey;
    if (!solicitorSnapshot.empty) {
      apiKey = solicitorSnapshot.docs[0].id;
    } else if (!originatorSnapshot.empty) {
      apiKey = originatorSnapshot.docs[0].id;
    }

    return AsyncHelper.onCompleted(apiKey);
  }
}

let _userHelper = null;
/**
 * Returns the firebase backend
 * @constant
 * @type { () => UserHelper}
 */
const getUserHelper = () => {
  if (!_userHelper) {
    _userHelper = new UserHelper();
  }
  return _userHelper;
};

export { getUserHelper, UserHelper };
