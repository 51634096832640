// eslint-disable-next-line no-unused-vars
import { T2a, AsyncResult } from "../../types";
import { AsyncHelper } from "./AsyncHelper";
import { AxiosService } from "./AxiosService";
import { FunnelLogMetadata, T2AGetAddressResponse, User } from "@claimsgate/core-types";
import { AxiosResponse } from "axios";
import { onCallGateway } from "./onCallGateway";
import { isClaimsGateFunctionReturnedError } from "../vue";

class T2AService {
  /**
   * Calls T2A to get list of addresses and parses a successful result.
   */
  static async runAddressSearch(
    postcode: string,
    metadata: FunnelLogMetadata
  ): Promise<AsyncResult<T2a.ParsedAddress[] | undefined, boolean | undefined, boolean | undefined>> {
    try {
      const result = await onCallGateway<"searchAddress">({
        functionName: "searchAddress",
        data: { postcode, ...metadata },
      });

      if (isClaimsGateFunctionReturnedError(result)) {
        console.error("t2a Address search returned error", result);
        return AsyncHelper.onError(true);
      }

      const parsedAddresses = T2AService.parseT2AaddressListResponse(
        result.data as T2AGetAddressResponse["address_list"]
      );

      if (parsedAddresses) {
        return AsyncHelper.onCompleted(parsedAddresses);
      } else {
        return AsyncHelper.onError(true);
      }
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Calls T2A to check user data against electorall roll.
   */
  static async runElectoralRollCheck(
    userData: T2a.RollCheckUserData,
    metadata: FunnelLogMetadata
  ): Promise<
    AsyncResult<{ matchPassed: boolean; electoralResult: any } | undefined, boolean | undefined, boolean | undefined>
  > {
    const matchFailedValues = {
      match_code_forename: "NO_MATCH",
      match_code_surname: "NO_MATCH",
      match_code_address: "NO_MATCH",
      validation_status: "NOT_FOUND",
    };
    try {
      // const rollCheckURL = T2AService.buildRollCheckURL(userData);
      // console.log("rollCheckURL", rollCheckURL);
      // const electoralResult = await AxiosService.get(rollCheckURL, {
      //   ...metadata,
      //   invocationFunction: "runElectoralRollCheck",
      //   invocationReason: "T2AElectoralRollCheck",
      // });

      const electoralResult = await onCallGateway<"electoralRollCheck">({
        functionName: "electoralRollCheck",
        data: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          postcode: userData.postcode,
          addressLine1: userData.addressLine1,
          userId: metadata.userId,
          claimId: metadata.claimId,
          funnelId: metadata.funnelId,
          pageId: metadata.pageId,
        },
      });
      console.log("GATEWAY electoralResult", electoralResult);
      const matchPassed = Object.keys(matchFailedValues).every((key) => {
        return electoralResult.data[key] && electoralResult.data[key] !== matchFailedValues[key];
      });
      return AsyncHelper.onCompleted({ matchPassed, electoralResult });
    } catch {
      return AsyncHelper.onException(matchFailedValues);
    }
  }

  /**
   */
  static parseT2AaddressListResponse(addressList: T2AGetAddressResponse["address_list"]): T2a.ParsedAddress[] | false {
    let result = [];
    try {
      result = addressList.map((address) => {
        return {
          text: address.addr_single_line,
          value: {
            id: address.address_id,
            addressLine1: `${address.line_1} ${address.line_2} ${address.line_3}`,
            addressLine2: "",
            city: (address.town.length > 0 ? address.town : address.place) ?? "",
            postcode: address.postcode,
          },
        };
      });
      if (result.length > 0) {
        return result;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }
}

// eslint-disable-next-line no-undef
export { T2AService };
